<template>
  <div class="public-container">
    <el-container>
      <el-header height="20px">
        <div class="navbar-box">
          <el-breadcrumb>
            <el-breadcrumb-item
              :class="item.active ? 'active' : ''"
              v-if="index <= activeIndex"
              v-for="(item, index) in list"
              :key="index"
              :to="item.active ? {} : { path: item.path }"
              >{{ item.name }}</el-breadcrumb-item
            >
          </el-breadcrumb>
        </div>
        <!-- <MenuFour :listName="'page'"></MenuFour> -->
      </el-header>
      <!--  -->
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </div>
</template>

<script>
//   import MenuFour from "@/components/menu/menu-four.vue";
import menuData from "@/components/menu/menu-data.js";
export default {
  name: "StoreWebIndex",

  // components: { MenuFour },

  directives: {},

  watch: {
    $route: {
      async handler(to, from) {
        if (to) {
          let list = menuData["page"];
          list.forEach((item, index) => {
            item.active = false;
            if (item.path == to.path) {
              item.active = true;
              this.activeIndex = index;
            }
          });
          this.list = list;
          // console.log(list);
          // console.log(to, menuData['page']);
        }
      },
      immediate: true, //第一次就执行,这个参数必须设置
    },
  },

  data() {
    return {
      list: [],
      activeIndex: 0,
    };
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
.el-header {
  padding: 0;
  // border-bottom: 1px solid #e5e9f2;
  background-color: #ffffff;
}

::v-deep .el-breadcrumb {
  .is-link {
    font-weight: 700;
    transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .is-link:hover {
    color: #409eff;
    cursor: pointer;
  }

  .active {
    .is-link {
      color: #606266;
      font-weight: unset;
      transition: unset;
    }

    .is-link:hover {
      cursor: unset;
    }
  }
}

.el-main {
  height: calc(100vh - 181px);
  box-sizing: border-box;
//   margin-top: 10px;
  background-color: #ffffff;
}
</style>
